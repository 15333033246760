import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import * as types from '../mutation-types';
import ZidAPI from '../../api/zid';

export const state = {
  records: {
    events: [],
    currentEvent: {},
    inboxes: [],
    token: '',
    status: '',
  },
  uiFlags: {
    isFetching: false,
    isUpdating: false,
  },
};

export const getters = {
  getEvents($state) {
    return $state.records.events;
  },
  getInboxesIDs($state) {
    return $state.records.inboxes;
  },
  getEvent: $state => {
    return $state.records.currentEvent;
  },
  getUIFlags($state) {
    return $state.uiFlags;
  },
  getStatus($state) {
    return $state.records.status;
  },
};

export const actions = {
  getZidInboxes: async ({ commit }) => {
    commit(types.default.SET_ZID_EVENT_UI_FLAG, { isFetching: true });
    try {
      const response = await ZidAPI.getZidInboxes();
      commit(types.default.SET_ZID_EVENT_UI_FLAG, { isFetching: false });
      commit(types.default.SET_ZID_INBOXES, response.data);
    } catch (error) {
      commit(types.default.SET_ZID_EVENT_UI_FLAG, { isFetching: false });
    }
  },
  getAllEvents: async ({ commit }, inbox_id) => {
    commit(types.default.SET_ZID_EVENT_UI_FLAG, { isFetching: true });
    try {
      const response = await ZidAPI.getAllEvents(inbox_id);
      commit(types.default.SET_ZID_EVENT_UI_FLAG, { isFetching: false });
      commit(types.default.SET_ALL_ZID_EVENTS, response.data);
    } catch (error) {
      commit(types.default.SET_ZID_EVENT_UI_FLAG, { isFetching: false });
    }
  },
  getEvent: async ({ commit }, params) => {
    commit(types.default.SET_ZID_EVENT_UI_FLAG, { isFetching: true });
    try {
      const response = await ZidAPI.getEvent(params);
      commit(types.default.SET_ZID_EVENT_UI_FLAG, { isFetching: false });
      commit(types.default.SET_ZID_EVENT, response.data);
    } catch (error) {
      commit(types.default.SET_ZID_EVENT_UI_FLAG, { isFetching: false });
    }
  },
  updateEvent: async ({ commit }, { id, ...eventParams }) => {
    commit(types.default.SET_ZID_EVENT_UI_FLAG, { isUpdating: true });
    try {
      const response = await ZidAPI.update({ id, eventParams });
      commit(types.default.EDIT_ZID_EVENT, {
        id,
        enabled: eventParams.enabled,
      });
      commit(types.default.SET_ZID_EVENT_UI_FLAG, { isUpdating: false });
    } catch (error) {
      commit(types.default.SET_ZID_EVENT_UI_FLAG, { isUpdating: false });
      throw error;
    }
  },
  sendZidStoreId: async ({ commit }, storeId) => {
    commit(types.default.SET_ZID_EVENT_UI_FLAG, { isFetching: true });
    try {
      await ZidAPI.sendZidStoreId(storeId);
      commit(types.default.SET_ZID_EVENT_UI_FLAG, { isFetching: false });
      return true;
    } catch (error) {
      commit(types.default.SET_ZID_EVENT_UI_FLAG, { isFetching: false });
      return false;
    }
  },
  zidCheck: async ({ commit }) => {
    commit(types.default.SET_ZID_EVENT_UI_FLAG, { isFetching: true });
    try {
      const response = await ZidAPI.checkZidStatue();
      commit(types.default.SET_ZID_EVENT_UI_FLAG, { isFetching: false });
      commit(types.default.SET_ZID_STATUS, response.data);
    } catch (error) {
      commit(types.default.SET_ZID_EVENT_UI_FLAG, { isFetching: false });
    }
  },
  disconnect: async ({ commit }) => {
    commit(types.default.SET_ZID_EVENT_UI_FLAG, { isFetching: true });
    try {
      const response = await ZidAPI.disconnect();
      commit(types.default.SET_ZID_EVENT_UI_FLAG, { isFetching: false });
      return true;
    } catch (error) {
      commit(types.default.SET_ZID_EVENT_UI_FLAG, { isFetching: false });
      return false;
    }
  },
};

export const mutations = {
  [types.default.SET_ZID_EVENT_UI_FLAG]($state, data) {
    $state.uiFlags = {
      ...$state.uiFlags,
      ...data,
    };
  },

  [types.default.SET_ALL_ZID_EVENTS]($state, data) {
    $state.records.events = data.events;
  },
  [types.default.SET_ZID_INBOXES]($state, data) {
    $state.records.inboxes = data.inboxes;
  },
  [types.default.SET_ZID_CONFIGURATION]($state, data) {
    $state.records.status = data.status;
    $state.records.token = data.connection_token;
  },
  [types.default.SET_ZID_STATUS]($state, data) {
    $state.records.status = data.status;
  },
  [types.default.SET_ZID_EVENT]($state, data) {
    $state.records.currentEvent = data;
  },
  [types.default.EDIT_ZID_EVENT]($state, data) {
    if ($state.records.events.length) {
      let event = $state.records.events.find(event => event.id == data.id);
      if (event)
        // for multi statues
        event.enabled = data.enabled;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
