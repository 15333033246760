import ApiClient from './ApiClient';

class ZidAPI extends ApiClient {
  constructor() {
    super('integrations/zid', { accountScoped: true });
  }

  getAllEvents(inbox_id) {
    return axios.get(`${this.url}_events`, {
      params: {
        inbox_id,
      },
    });
  }
  getEvent(params) {
    return axios.get(`${this.url}_events/${params.eventId}`, {
      params: {
        inbox_id: params.inboxId,
      },
    });
  }
  getZidInboxes() {
    return axios.get(`${this.url}_events/inboxes`);
  }
  sendZidStoreId(storeId) {
    return axios.post(`${this.url}_connections/connect`, {
      store_id: storeId,
    });
  }
  checkZidStatue() {
    return axios.get(`${this.url}_connections/check`);
  }
  disconnect() {
    return axios.post(`${this.url}_connections/disconnect`);
  }
  update(params) {
    return axios.patch(`${this.url}_events/${params.id}`, {
      ...params.eventParams,
    });
  }
}

export default new ZidAPI();
