import { frontendURL } from '../../../../helper/URLHelper';

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/settings/integrations'),
      component: () => import('../Wrapper'),
      props: params => {
        const showBackButton = params.name !== 'settings_integrations';
        const backUrl =
          params.name === 'settings_integrations_integration'
            ? { name: 'settings_integrations' }
            : '';
        return {
          headerTitle: 'INTEGRATION_SETTINGS.HEADER',
          icon: 'flash-on',
          showBackButton,
          backUrl,
        };
      },
      children: [
        {
          path: '',
          name: 'settings_integrations',
          component: () => import('./Index'),
          roles: ['administrator'],
          meta: {
            permission: 'show_integrations',
          },
        },
        {
          path: 'webhook',
          component: () => import('./Webhooks/Index'),
          name: 'settings_integrations_webhook',
          roles: ['administrator'],
        },
        {
          path: 'dashboard-apps',
          component: () => import('./DashboardApps/Index'),
          name: 'settings_integrations_dashboard_apps',
          roles: ['administrator'],
        },
        {
          path: 'salla',
          name: 'settings_integrations_salla',
          component: () => import('./salla/SallaConfiguration.vue'),
          roles: ['administrator'],
        },
        {
          path: 'salla/events',
          name: 'settings_integrations_salla_events',
          component: () => import('./salla/SallaEvents.vue'),
          roles: ['administrator'],
        },
        {
          path: 'salla/:eventId',
          name: 'settings_integrations_salla_event',
          component: () => import('./salla/SallaSingleEvent.vue'),
          props: true,
        },
        {
          path: 'zid',
          name: 'settings_integrations_zid',
          component: () => import('./Zid/ZidConfiguration.vue'),
          roles: ['administrator'],
        },
        {
          path: 'zid/events',
          name: 'settings_integrations_zid_events',
          component: () => import('./Zid/ZidEvents.vue'),
          roles: ['administrator'],
        },
        {
          path: 'zid/:eventId',
          name: 'settings_integrations_zid_event',
          component: () => import('./Zid/ZidSingleEvent.vue'),
          props: true,
        },
        {
          path: ':integration_id',
          name: 'settings_integrations_integration',
          component: () => import('./IntegrationHooks'),
          roles: ['administrator'],
          props: route => ({
            integrationId: route.params.integration_id,
          }),
        },
      ],
    },
  ],
};
